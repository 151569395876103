.agreement {
  padding: 20px 16px;
  font-size: 1rem;
  line-height: 1.5rem;
}

.agreement h1,
.agreement h2 {
  font-size: 1.2rem;
  line-height: 1.7rem;
  text-align: center;
}

.agreement li + li {
  margin-top: 1rem;
}

.agreement p + p {
  margin-top: 1rem;
}