.container {
  margin-bottom: 16px;
  padding: 16px;
  background-color: #fff;
  border-radius: 16px;
}

.nameWrapper {
  margin-bottom: 16px;
  display: flex;
  gap: 16px;
  align-items: center;
}

.profileRow {
  position: relative;
  height: 54px;
  padding: 24px 0 8px;
  border-bottom: 1px solid var(--gray);
}

.profileRowButton {
  position: absolute;
  top: 14px;
  right: 0px;
}

.label {
  position: absolute;
  top: 8px;
  font-size: 12px;
  color: var(--text-gray);
}

/* CARDS */
.cardsBlock {
  margin-bottom: 16px;
  padding: 8px 16px;
  background-color: #fff;
  border-radius: 16px;
}

.cardItem {
  width: 100%;
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cardRow {
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.cardItem + .cardItem {
  border-top: 1px solid var(--gray);
}
