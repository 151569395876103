.row {
  position: relative;
  display: flex;
  align-items: center;
}

.column {
  position: relative;
  display: flex;
  flex-direction: column;
}

.oh {
  overflow: hidden;
}

.pointer {
  cursor: pointer;
  user-select: none;
}

.row.top {
  align-items: start;
}

.row.bottom {
  align-items: end;
}

.row.center {
  justify-content: center;
}

.row.right {
  justify-content: right;
}

.column.center {
  align-items: center;
  justify-content: center;
}

.column.top {
  justify-content: start;
}

.column.right {
  align-items: end;
}

.opposite {
  justify-content: space-between;
}

.wide {
  width: 100%;
}

.column.scroll {
  overflow-y: auto;
}

.fix {
  flex: 0 0 auto;
}

.spring {
  flex: 1 1 0;
}

.tall {
  height: 100%;
}

.wrap {
  flex-wrap: wrap;
}

.parallel {
  > * {
    align-self: stretch;
  }
}

.light {
  background-color: var(--light);
}

.secondary {
  background-color: var(--gray);
}
