@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;600;700;800&family=Inter:wght@100..900&display=swap');

* {
  box-sizing: border-box;
  --gray: #EFEFF0;
  --text-gray: #959ab4;
  --text: #35373f;
  --gray1: #babdcc;
  --light: #f8f8f8;
  --main-background: #EDEFF5;
  --main-white: #FFFFFF;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  color: var(--text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
}

button:focus,
button:active {
  outline: none;
}

input,
textarea {
  margin: 0;
  padding: 0;
  appearance: none;
  outline: none;
  border: none;
}

::-webkit-file-upload-button {
  display: none;
}

ul {
  margin: 0;
  padding: 0 20px;
}

p {
  margin: 0;
  padding: 0;
}

a,
a:link,
a:visited,
a:hover {
  text-decoration: none;
  color: #35373f;
  cursor: pointer;
}

.suspense {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ck.ck-toolbar.ck-toolbar_grouping {
  display: none;
}

.ck.ck-dropdown.ck-toolbar__grouped-dropdown.ck-toolbar-dropdown {
  visibility: hidden;
}

.ck-editor__editable_inline {
  border-radius: 12px !important;
  border: 1px solid #babdcc !important;
}

.ck-editor__editable_inline.ck-focused,
.ck-editor__editable_inline {
  min-height: 160px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: #35373f;
}

.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
  border: 1px solid #babdcc !important;
  box-shadow: none !important;
}

.ck.ck-powered-by {
  display: none !important;
}

.ck-placeholder,
.ck-placeholder:before {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: #959ab4 !important;
}

.ck.ck-editor__main > .ck-editor__editable {
  background: none !important;
}

.react-multi-carousel-track {
  width: 100%;
}

.react-multiple-carousel__arrow {
  background: none !important;
}

.react-multiple-carousel__arrow--right {
  right: 0px !important;
}

.react-multiple-carousel__arrow--left {
  left: 0px !important;
}
