.vjs-big-play-centered,
.vjs-tech {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.video-js .vjs-control-bar {
  height: 20px;
  padding: 0px;
  align-items: flex-end;
  border-radius: 0 0 8px 8px;
  background: rgb(19 19 20 / 60%);
  font-size: 12px;
}

.video-js .vjs-poster img {
  border-radius: 8px;
  object-fit: cover;
}

.video-js .vjs-control {
  width: 24px;
  height: 24px;
  position: absolute;
}

.video-js .vjs-control-bar {
  height: 6px;
}

.vjs-button > .vjs-icon-placeholder::before {
  width: 24px;
  height: 24px;
}

.video-js .vjs-play-control .vjs-icon-placeholder::before {
  content: '';
}

.video-js .vjs-fullscreen-control {
  display: none;
}

.video-js .vjs-play-control {
  display: none;
}

.video-js .vjs-play-control.vjs-ended .vjs-icon-placeholder::before {
  content: '';
}

.video-js .vjs-progress-control {
  top: 0px;
  left: 0px;
  height: 6px;
  width: 100%;
}

.video-js .vjs-progress-control .vjs-progress-holder {
  margin: 0;
}

.video-js .vjs-play-progress:before {
  display: none;
}

.video-js .vjs-progress-holder {
  height: 6px;
}

.video-js .vjs-play-progress {
  background-color: #33E330;
}

.video-js .vjs-slider,
.video-js .vjs-load-progress,
.video-js .vjs-load-progress div {
  background: #000;
}

.video-js .vjs-volume-panel,
.video-js .vjs-picture-in-picture-control,
.video-js .vjs-remaining-time {
  display: none;
}

.vjs-has-started.vjs-paused .vjs-big-play-button {
  display: block;
  width: 0;
  height: 0;
  left: calc(50% + 10px);
}

.video-js .vjs-big-play-button {
  background-color: transparent;
  border: none;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHZpZXdCb3g9IjAgMCA2MCA2MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMzAiIGN5PSIzMCIgcj0iMzAiIGZpbGw9ImJsYWNrIiBmaWxsLW9wYWNpdHk9IjAuNzUiLz4KPHBhdGggZD0iTTQxIDI4LjI2NzlDNDIuMzMzMyAyOS4wMzc3IDQyLjMzMzMgMzAuOTYyMyA0MSAzMS43MzIxTDI2IDQwLjM5MjNDMjQuNjY2NyA0MS4xNjIxIDIzIDQwLjE5OTkgMjMgMzguNjYwM0wyMyAyMS4zMzk3QzIzIDE5LjgwMDEgMjQuNjY2NyAxOC44Mzc5IDI2IDE5LjYwNzdMNDEgMjguMjY3OVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=')
}
