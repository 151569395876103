.container {
  width: 100%;
  margin-bottom: 16px;
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 10px;
}

.filterRow {
  display: flex;
  align-items: center;
  gap: 8px;
}

.search {
  padding: 0 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.sortButton {
  padding: 0px 16px;
}
