.typo {
  line-height: 1.5;
}

.medium {
  font-weight: 500;
}

.semi {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.mono {
  font-family: var(--ui-font-family-monospace), monospace;
}

.center {
  text-align: center;
}

.ellipsis {
  display: block;
  flex-shrink: 1;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.one {
  line-height: 1;
}

.nowrap {
  white-space: nowrap;
}

.wrap {
  white-space: wrap;
  word-break: break-word;
}

.balance {
  white-space: wrap;
  text-wrap: balance;
}

.upper {
  text-transform: uppercase;
}

.code {
  white-space: pre;
}

.pre {
  white-space: pre-wrap;
}

.default {
  color: var(--looky-text);
}

.white {
  color: var( --looky-white);
}

.secondary {
  color: var(--looky-text-gray);
}

.silver {
  color: var(--looky-silver);
}

.success {
  color: var(--looky-success);
}

.error {
  color: var(--ui-text-color-error);
}

.xs {
  font-size: 0.75rem;
}

.sm {
  font-size: 0.875rem;
}

.base {
  font-size: 1rem;
}

.lg {
  font-size: 1.125rem
}

.xl {
  font-size: 1.25rem;
}

.xxl {
  font-size: 1.5rem;
}
